import React, {useContext, useRef, useState} from 'react'
import {Settings, X} from 'react-feather'
import {Text} from 'rebass'
import styled, {ThemeContext} from 'styled-components'
import {useOnClickOutside} from '../../hooks/useOnClickOutside'
import {ApplicationModal} from '../../state/application/actions'
import {useModalOpen, useToggleSettingsMenu} from '../../state/application/hooks'
import {
  useDarkModeManager,
  useExpertModeManager,
  useUserTransactionTTL,
  useUserSlippageTolerance
} from '../../state/user/hooks'
import {TYPE} from '../../theme'
import {ButtonError} from '../Button'
import {AutoColumn} from '../Column'
import Modal from '../Modal'
import QuestionHelper from '../QuestionHelper'
import {RowBetween, RowFixed} from '../Row'
import Toggle from '../Toggle'
import TransactionSettings from '../TransactionSettings'
import {useTranslation} from 'react-i18next'
import {StyledMenu, StyledMenuButton, MenuFlyout} from "../StyledMenu";

const StyledMenuIcon = styled(Settings)`
  height: 20px;
  width: 20px;

  > * {
    stroke: ${({theme}) => theme.text1};
  }
`

const StyledCloseIcon = styled(X)`
  height: 20px;
  width: 20px;

  :hover {
    cursor: pointer;
  }

  > * {
    stroke: ${({theme}) => theme.text1};
  }
`

const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 14px;
`

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.bg3};
`

const ModalContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: ${({theme}) => theme.bg2};
  border-radius: 20px;
`

const SettingsMenuFlyout = styled(MenuFlyout)`

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 18.125rem;
    right: -46px;
  `};

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-width: 18.125rem;
    top: -22rem;
  `};
`

export default function SettingsTab() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()

  const theme = useContext(ThemeContext)
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

  const [ttl, setTtl] = useUserTransactionTTL()

  const [expertMode, toggleExpertMode] = useExpertModeManager()

  const [darkMode, toggleDarkMode] = useDarkModeManager()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)

  useOnClickOutside(node, open ? toggle : undefined)

  const {t} = useTranslation()
  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{padding: '0 2rem'}}>
              <div/>
              <Text fontWeight={500} fontSize={20}>
                {t('settings.areYouSure')}
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)}/>
            </RowBetween>
            <Break/>
            <AutoColumn gap="lg" style={{padding: '0 2rem'}}>
              <Text fontWeight={500} fontSize={20}>
                {t('settings.expertInfo')}
              </Text>
              <Text fontWeight={600} fontSize={20}>
                {t('settings.expertWarningCAPS')}
              </Text>
              <ButtonError
                error={true}
                padding={'12px'}
                onClick={() => {
                  if (window.prompt(t('settings.confirmExpertMode')) === t('settings.confirm')) {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}
              >
                <Text fontSize={20} fontWeight={500} id="confirm-expert-mode">
                  {t('settings.turnOnExpertMode')}
                </Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
      <StyledMenuButton onClick={toggle} id="open-settings-dialog-button">
        <StyledMenuIcon/>
        {expertMode ? (
          <EmojiWrapper>
            <span role="img" aria-label="wizard-icon">
              🧙
            </span>
          </EmojiWrapper>
        ) : null}
      </StyledMenuButton>
      {open && (
        <SettingsMenuFlyout>
          <AutoColumn gap="md" style={{padding: '1rem'}}>
            <Text fontWeight={600} fontSize={14}>
              {t('settings.transactionSettings')}
            </Text>
            <TransactionSettings
              rawSlippage={userSlippageTolerance}
              setRawSlippage={setUserslippageTolerance}
              deadline={ttl}
              setDeadline={setTtl}
            />
            <Text fontWeight={600} fontSize={14}>
              {t('settings.interfaceSettings')}
            </Text>
            <RowBetween>
              <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  {t('settings.toggleExpertMode')}
                </TYPE.black>
                <QuestionHelper text={t('settings.expertModeHelper')}/>
              </RowFixed>
              <Toggle
                id="toggle-expert-mode-button"
                isActive={expertMode}
                toggle={
                  expertMode
                    ? () => {
                      toggleExpertMode()
                      setShowConfirmation(false)
                    }
                    : () => {
                      toggle()
                      setShowConfirmation(true)
                    }
                }
              />
            </RowBetween>
            <RowBetween>
              <RowFixed>
                <TYPE.black fontWeight={400} fontSize={14} color={theme.text2}>
                  {t('settings.toggleDarkMode')}
                </TYPE.black>
              </RowFixed>
              <Toggle isActive={darkMode} toggle={toggleDarkMode}/>
            </RowBetween>
          </AutoColumn>
        </SettingsMenuFlyout>
      )}
    </StyledMenu>
  )
}
