import React from 'react'

interface Props {
  size: number
  fillColor: string
}
const Swap: React.FC<Props> = props => {
  const { size, fillColor } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 21">
      <path
        id="Swap"
        d="M19,0H2A2,2,0,0,0,0,2V19a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V2A2,2,0,0,0,19,0ZM16.491,7.174v.243c0,2.118.011,4.236,0,6.354A3.751,3.751,0,0,1,14.485,17.3a3.31,3.31,0,0,1-4.6-2.042,4.7,4.7,0,0,1-.25-1.561V7.283a2.042,2.042,0,0,0-.684-1.617,1.615,1.615,0,0,0-2.275.2,1.633,1.633,0,0,0-.207.313A2.143,2.143,0,0,0,6.209,7.2v6.5a.561.561,0,0,0,0,.147h2.5L5.34,17.577,1.976,13.845H4.492V7.255A3.827,3.827,0,0,1,6.182,3.9a3.2,3.2,0,0,1,4.432.929l0,.007a4.228,4.228,0,0,1,.73,2.473v6.411a2.05,2.05,0,0,0,.69,1.631,1.618,1.618,0,0,0,2.278-.21,1.679,1.679,0,0,0,.2-.311,2.2,2.2,0,0,0,.259-1.041V7.348c0-.049,0-.1-.006-.163H12.287l3.374-3.744,3.363,3.733Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default Swap
